import React from 'react'
import {Link} from 'gatsby'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import details1 from '../assets/images/services/services-details1.jpg'
import project2 from '../assets/images/projects/project2.jpg'
import charts from '../assets/images/services/charts.jpg'
import icon1 from '../assets/images/services/service-icon1.png'
import icon2 from '../assets/images/services/service-icon2.png'
import icon3 from '../assets/images/services/service-icon3.png'
 
const Details = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Case management" 
                homePageText="Services" 
                homePageUrl="/services" 
                activePageText="Case management" 
            />
            <ServiceDetailsContent />
            <RelatedProjects />
            <Footer />
        </Layout>
    );
}

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">Case management</span>
                            <h3>Manage relationships</h3>
                            <p>We support the management of the relationships with your beneficiaries, and their involvement in your activities.</p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Features</h3>
                                        <ul>
                                            <li>Case Management</li>
                                            <li>Follow-up</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p> It is important that an organization knows how to manage the relationships that are created with its beneficiaries. In order to make the most of this relationship, so that the products and services are always better suited to the needs of the people for whom they are designed, and to be able to account for the activities carried out. With our tools it is possible to easily create a personalized registry for each beneficiary and then associate specific sheets for each activity or group of activities repeated over time. This model fits a very wide variety of application domains, from school attendance records, to medical follow-up, to access to anti-poverty services. 
</p>


                            <h3>Technologies</h3>
                            <ul className="technologies-features">
                                <li><span>DINO / DEWCO</span></li>
                                <li><span>Applicazioni Web</span></li>
                                <li><span>Mobile APP</span></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

const ServiceSidebar = () => {
    return (
        <div className="services-details-info">
            <ul className="services-list">
                <li>
                    <Link to="/app-development" >
                       App development
                    </Link>
                </li>
                <li>
                    <Link to="/digital-transformation">
                        Digital transformation
                    </Link>
                </li>
                <li>
                    <Link to="/social-balance-monitoring-evaluation">
                        Social balance, monitoring and evaluation
                    </Link>
                </li>
                <li>
                    <Link to="/beneficiary-management" className="active">
                    Case management
                    </Link>
                </li>
                <li>
                    <Link to="/data-analysis">
                        Data Analysis
                    </Link>
                </li>
                <li>
                    <Link to="/artificial-intelligence">
                        Artificial intelligence
                    </Link>
                </li>
            </ul>
        </div>
    )
}

const RelatedProjects = () => {
    return (
        <section className="services-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <h2>Developed projects</h2>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box ">
                            <div className="icon">
                                <img src={icon2} alt="about" />
                            </div>
                            <h3>
                                <Link to="/project/cri">
	    				Relief Dino
                                </Link>
                            </h3>
                            <p>Data collection and analysis platform to manage the supply chain, distribution of goods and the beneficiary</p>

                            <Link to="/project/cri" className="read-more-btn">
                            Read more <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box ">
                            <div className="icon">
                                <img src={icon2} alt="about" />
                            </div>
                            <h3>
                                <Link to="/project/change">
                                    Change
                                </Link>
                            </h3>
                            <p>School attendance monitoring in Ethiopia with CIAI and DFID</p>

                            <Link to="/project/change" className="read-more-btn">
                            Read more <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon2} alt="about" />
                            </div>
                            <h3>
                                <Link to="/project/para-todas-as-crianca">
                                    Para Todas a Criança
                                </Link>
                            </h3>
                            <p>Para Todas a Criança with Save the Children in Mozambique</p>
                            
                            <Link to="/project/para-todas-as-crianca" className="read-more-btn">
                             Read more <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>    
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon2} alt="about" />
                            </div>
                            <h3>
                                <Link to="/project/ibva">
	    				Dino IBVA
                                </Link>
                            </h3>
                            <p>Web Platform to manage users and serviced offered by IBVA</p>
                            
                            <Link to="/project/ibva" className="read-more-btn">
                            Read more <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Details
